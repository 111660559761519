import { useState, useEffect } from "react";
import classNames from "classnames";
import { ThreeCircles } from "react-loader-spinner";

import styles from "./style.module.css";

interface Props {
  className?: string;
  delay: number;
}

function Loading({ className, delay }: Props) {
  const [visible, setVisible] = useState(!delay);

  useEffect(() => {
    if (delay) {
      setTimeout(() => setVisible(true), delay);
    }
  }, [delay]);

  return visible ? (
    <div className={classNames(styles.loading, className)}>
      <ThreeCircles
        outerCircleColor="#CE3889"
        middleCircleColor="#CE3889"
        innerCircleColor="#FFEAF5"
      />
    </div>
  ) : (
    <></>
  );
}

export default Loading;
