import { useEffect } from "react";
import { useRouter } from "next/router";
import { datadogRum } from "@datadog/browser-rum";

import Button from "@/atoms/Button";
import ArrowLeft from "./arrow-left.svg";
import Illustration from "./page-not-found.svg";
import styles from "./style.module.css";

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

function NotFoundError({ resetErrorBoundary }: Props) {
  const router = useRouter();
  useEffect(() => datadogRum.addError("NotFoundError page shown"));
  const handleBack = () => {
    router.replace(document.location.origin);
    setTimeout(() => {
      resetErrorBoundary();
    }, 100);
  };

  return (
    <div className={styles.content}>
      <div className={styles.illustration}>
        <Illustration width="244" height="244" />
      </div>

      <div className={styles.message}>
        <div className={styles.header}>Page Not Found</div>
        <p className={styles.caption}>
          The Page you&apos;re trying to access does not exist.
        </p>
      </div>

      <Button onClick={handleBack}>
        <ArrowLeft />
        Go back to home
      </Button>
    </div>
  );
}

export default NotFoundError;
