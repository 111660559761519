import React, { useEffect, type FC } from "react";
import { AppContext } from "next/app";
import { LicenseInfo } from "@mui/x-license-pro";
import { type NextRouter } from "next/router";
import { Toaster, toast } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { StyledEngineProvider } from "@mui/material/styles";
import ReactModal from "react-modal";
import TagManager from "react-gtm-module";

import AuthedLayout from "@/components/AuthedLayout";
import { ErrorBoundary } from "@/lib/errors";
import { ToastContext } from "@/lib/contexts/toast";
import { version, env } from "@/lib/utils";
import Toast, { type ToastType } from "@/atoms/Toast";

import "../styles/colors.css";
import "../styles/global.css";

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY);

if (env !== "development") {
  const site = "datadoghq.com";
  const service = "lumopath";

  datadogRum.init({
    applicationId: "30530420-bef7-444e-88c8-b1590d1ad8dd",
    clientToken: "pubd55a9fa693d35c4d74a80786e167da44",
    site,
    service,
    env,
    version,
    allowedTracingUrls: [
      "https://app.culturescience.io",
      "https://client.staging.culturescience.io",
      "https://api.staging.culturescience.io",
      "https://app.lumopath.ai",
      "https://app.staging.lumopath.ai",
      "https://api.staging.lumopath.ai",
    ],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogLogs.init({
    clientToken: "pub6c0fba22d5023be81d0bd009fd03d09a",
    site,
    service,
    env,
    version,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    forwardReports: "all",
    sessionSampleRate: 100,
  });
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1, // Calendar week starts on Monday
});

const TOAST_OPTIONS = {
  style: {
    background: "var(--black-100)",
    color: "var(--neutral-white)",
    width: "400px",
    padding: 0,
    margin: 0,
  },
};

function setToast(
  toastType: ToastType,
  title: string,
  description: string,
  duration = 4000,
) {
  toast(
    <Toast toastType={toastType} title={title} description={description} />,
    {
      duration,
    },
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (retryCount, error) => {
        datadogRum.addError(error, { retryCount: retryCount });
        return retryCount <= 3 ? true : false;
      },
    },
  },
});

interface AppProps {
  Component: FC;
  pageProps: object;
  router: NextRouter;
  NODE_ENV: string;
}

function App({ Component, pageProps, router, NODE_ENV }: AppProps) {
  useEffect(() => {
    ReactModal.setAppElement("main");
    if (NODE_ENV !== "development") {
      TagManager.initialize({
        gtmId: "GTM-NLQP7JB",
      });
    }
  }, [NODE_ENV]);

  return (
    <StyledEngineProvider injectFirst>
      <ErrorBoundary>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <ToastContext.Provider value={{ setToast }}>
              <Toaster
                expand={false}
                visibleToasts={1}
                position="top-center"
                closeButton
                toastOptions={TOAST_OPTIONS}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <main>
                  {router.pathname.match(/design-system/i) ? (
                    // Render the design system without a layout (no nav, etc.)
                    <Component {...pageProps} />
                  ) : (
                    // Everything else gets the AuthedLayout with navigation
                    <AuthedLayout>
                      <Component {...pageProps} />
                    </AuthedLayout>
                  )}
                </main>
              </LocalizationProvider>
            </ToastContext.Provider>
          </QueryClientProvider>
        </UserProvider>
      </ErrorBoundary>
    </StyledEngineProvider>
  );
}

App.getInitialProps = (appContext: AppContext) => {
  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: "/" });
    appContext.ctx.res.end();
    return;
  }

  return {
    NODE_ENV: process.env.NODE_ENV,
  };
};

export default App;
