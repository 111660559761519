import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

import Button from "@/atoms/Button";
import Illustration from "./system-issue.svg";
import styles from "./style.module.css";

function SystemError() {
  useEffect(() => datadogRum.addError("SystemError page shown"));
  return (
    <div className={styles.content}>
      <div className={styles.illustration}>
        <Illustration width="403" height="244" />
      </div>

      <div className={styles.message}>
        <div className={styles.header}>System Issue</div>
        <p className={styles.caption}>
          We apologize for the inconvenience. Lumopath is experiencing an
          unusually high volume of users and/or load, causing temporary
          disruptions. Our team is doing everything possible to resolve the
          issue quickly.
        </p>
        <p className={styles.caption}>
          Your understanding is appreciated, and our support team is available
          at{" "}
          <a href="mailto:support@lumopath.ai" target="_blank" rel="noreferrer">
            support@lumopath.ai
          </a>{" "}
          if you need help.
        </p>
      </div>

      <Button onClick={() => window.location.reload()}>Retry</Button>
    </div>
  );
}

export default SystemError;
