import classnames from "classnames";
import {
  useRef,
  useCallback,
  useEffect,
  type ReactNode,
  type MouseEventHandler,
} from "react";

import styles from "./style.module.css";

interface Props {
  open: boolean;
  button?: () => JSX.Element;
  closeOnInsideClick?: boolean;
  closeOnOutsideClick?: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
}

function Popup({
  open,
  button,
  closeOnOutsideClick = true,
  closeOnInsideClick = false,
  onClose,
  children,
  className,
  ...otherProps
}: Props) {
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (onClose && ref.current && !ref.current.contains(event.target)) {
        closeOnOutsideClick && onClose();
      }
    },
    [onClose, closeOnOutsideClick]
  );

  const handleClickInside: MouseEventHandler<HTMLDialogElement> = useCallback(
    (event) => {
      if (onClose && ref.current && ref.current.contains(event.target)) {
        closeOnInsideClick && onClose();
      }
    },
    [onClose, closeOnInsideClick]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const classes = classnames(styles.dialog, className);

  return (
    <div ref={ref} className={styles.root}>
      {button && button()}
      <dialog
        open={open}
        className={classes}
        {...otherProps}
        onClick={handleClickInside}
      >
        {children}
      </dialog>
    </div>
  );
}

export default Popup;
