import { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/legacy/image";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import {
  some,
  camelCase,
  snakeCase,
  startCase,
  omit,
  trim,
  last,
} from "lodash";
import classNames from "classnames";

import { useUser } from "@auth0/nextjs-auth0/client";
import ExploreIcon from "./slash-circle.svg";
import DownIcon from "./chevron-down.svg";
import UpIcon from "./chevron-up.svg";
import PlusIcon from "./plus-lg.svg";
import MinusIcon from "./dash-lg.svg";
import ExclamationIcon from "./exclamation-triangle.svg";
import PersonIcon from "./person.svg";
import CustomizableTableIcon from "@/organisms/LeftPaneNav/customizable-table-icon.svg";
import Popup from "@/atoms/Popup";
import ChevronRight from "./chevron-right.svg";
import ShareIcon from "./share.svg";

import styles from "./style.module.css";

const PENDO_GUIDE_PARAMS = {
  pendo: "ijc3ldsV-Aob7PzWFlKEUP8FAtg",
  step: "t5IJ7ydUV5vSMAQ1qtvX_APcqJ0",
};

const MODULE_NAV_ITEMS = {
  PEOPLE_HEALTH: {
    BURNOUT: "/aggregates/burnout",
    MANAGER_1_ON_1_TIME: "/aggregates/manager-1-on-1-time",
    FOCUS_TIME: "/aggregates/focus-time",
    ACTIVE_HOURS: "/aggregates/active-hours",
  },
  COMMUNICATION_HEALTH: {
    CONNECTIVITY: "/aggregates/connectivity",
    RESPONSIVENESS: "/aggregates/responsiveness",
    TIME_DISTRIBUTION: "/aggregates/time-distribution",
    ACTIVITY_BREAKOUT: "/activity-breakout",
  },
  COST_TO_SERVE: {
    OVERVIEW: "/cost-to-serve",
    DETAILS: "/customer-breakout",
  },
  CUSTOM: {
    INTERNAL_TIME_CONSUMED: "/aggregates/internal-time-consumed",
    DEVELOPMENT_HEALTH: "/aggregates/development-health",
    EMPLOYEE_TURNOVER: "/aggregates/employee-turnover",
    ORG_CHART: "/org-chart",
    TICKETING: "/aggregates/ticketing",
  },
};

export const NAV_ITEMS = Object.assign(
  {
    ACTIVE_ALERTS: "/alerts/active",
    ALERTS_SETTINGS: "/alerts/settings",
    REQUEST_A_REPORT: "/request-a-report",
    CUSTOMIZABLE_TABLE: "/customizable-table",
    INTEGRATIONS: "/integrations",
  },
  ...Object.values(MODULE_NAV_ITEMS),
);

export const INITIAL_MENU_ATOM = {
  explore: true,
  alerts: true,
  peopleHealth: false,
  custom: true,
  communicationHealth: false,
};

export const menuAtom = atomWithStorage("menu", INITIAL_MENU_ATOM);

/////////////////////////////////
// Utility Functions
/////////////////////////////////
/*
  The name of the flag in the backend's module config will be the module's nav item path, snake-cased.

  Extreme example where all modules are turned off:

  moduleConfig = {
    "burnout": false,
    "manager_1_on_1_time": false,
    "focus_time": false,
    "communication_hours":false,
    "connectivity": false,
    "responsiveness": false
  }
 */
function showModuleNavItem(user, modulesConfig, item) {
  const moduleConfigKey = snakeCase(last(item.split("/")));
  const role = user?.role;
  const email = user?.email;

  const ACTIVE_HOURS_USERS = [
    "roderick@lovevery.com",
    "jennifer@lovevery.com",
    "monica@shipwell.com",
    "aquinlan@caring.com",
    "val@fabric.inc",
    "admin@fabric.inc",
    "alexandra@fabric.inc",
    "navneet.gupta@fabric.inc",
    "nate@incendiumstrategies.com",
  ];

  if (moduleConfigKey === "active_hours") {
    if (role === "super_admin") {
      return true;
    }
    if (ACTIVE_HOURS_USERS.includes(email)) {
      return true;
    }
    return false;
  }

  if (moduleConfigKey === "customizable_table" && role === "ic") {
    return false;
  }

  if (
    moduleConfigKey === "employee_turnover" ||
    moduleConfigKey === "org_chart"
  ) {
    return (
      modulesConfig[moduleConfigKey] === true &&
      (role === "admin" || role === "super_admin")
    );
  }

  if (moduleConfigKey === "development_health") {
    return (
      modulesConfig[moduleConfigKey] === true ||
      modulesConfig["github"] === true
    );
  }

  if (moduleConfigKey === "internal_time_consumed") {
    return modulesConfig[moduleConfigKey] === true;
  }

  if (moduleConfigKey === "ticketing") {
    return modulesConfig[moduleConfigKey] === true;
  }

  if (["cost_to_serve", "customer_breakout"].includes(moduleConfigKey)) {
    return (
      modulesConfig["cost-to-serve"] === true ||
      modulesConfig[`cost-to-serve-${email}`] === true
    );
  }

  return modulesConfig[moduleConfigKey] !== false;
}

function formatModuleName(moduleName) {
  const formattedModuleName = startCase(camelCase(moduleName));

  switch (moduleName) {
    case "MANAGER_1_ON_1_TIME":
      return formattedModuleName.replace("1 On 1", "1:1");
    default:
      return formattedModuleName;
  }
}

//////////////////////////////
// <NavItem />
/////////////////////////////
function NavItem({
  user,
  isMenuOpen,
  menuStateKey,
  moduleCategory,
  currentQueryParams,
  modulesConfig,
  handleToggle,
}) {
  return (
    <li className={classNames({ [styles.open]: isMenuOpen })}>
      <span onClick={() => handleToggle(menuStateKey)}>
        <a>{startCase(menuStateKey)}</a>
        <a className={styles.expandButton}>
          {isMenuOpen ? <MinusIcon /> : <PlusIcon />}
        </a>
      </span>
      <ul>
        {Object.keys(MODULE_NAV_ITEMS[moduleCategory]).map((moduleName) => {
          const modulePath = MODULE_NAV_ITEMS[moduleCategory][moduleName];

          if (!showModuleNavItem(user, modulesConfig, modulePath)) {
            return null;
          }

          return (
            <li key={moduleName}>
              <Link
                href={{
                  pathname: modulePath,
                  query: omit(currentQueryParams, [
                    "metricId",
                    "customTargets[time-distribution-external]",
                  ]),
                }}
              >
                {formatModuleName(moduleName)}
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

///////////////////////////////
// <LeftPaneNav />
//////////////////////////////
function LeftPaneNav() {
  /////////////////////////////////
  // State
  /////////////////////////////////
  const router = useRouter();
  const [menuState, setMenuState] = useAtom(menuAtom);
  const { user } = useUser();
  const modulesConfig = user?.csMetadata?.modules || {};

  /////////////////////////////////
  // Event handlers
  /////////////////////////////////
  function handleToggle(itemKey) {
    return setMenuState({
      ...menuState,
      [itemKey]: !menuState[itemKey],
    });
  }

  function activeClass(linkPath) {
    if (router.pathname.match(`^${linkPath}`)) {
      return styles.active;
    }
    return null;
  }

  return (
    <div id="left-pane" className={styles.container}>
      <div className={styles.logo}>
        <Image src="/logo.svg" width={190} height={40} />
      </div>

      <nav id="left-pane-nav" className={styles.navigation}>
        <ul>
          <li
            className={classNames({
              [styles.open]: menuState.alerts,
            })}
          >
            <span onClick={() => handleToggle("alerts")}>
              <ExclamationIcon />
              Alerts
              <a className={styles.expandButton}>
                {menuState.alerts ? <DownIcon /> : <UpIcon />}
              </a>
            </span>

            <ul>
              {showModuleNavItem(
                user,
                modulesConfig,
                NAV_ITEMS.ACTIVE_ALERTS,
              ) && (
                <li className={activeClass(NAV_ITEMS.ACTIVE_ALERTS)}>
                  <Link href={NAV_ITEMS.ACTIVE_ALERTS}>Active Alerts</Link>
                </li>
              )}
              <li className={activeClass(NAV_ITEMS.ALERTS_SETTINGS)}>
                <Link href={NAV_ITEMS.ALERTS_SETTINGS}>Settings</Link>
              </li>
            </ul>
          </li>

          <li className={classNames({ [styles.open]: menuState.explore })}>
            <span onClick={() => handleToggle("explore")}>
              <ExploreIcon />
              Explore
              <a className={styles.expandButton}>
                {menuState.explore ? <DownIcon /> : <UpIcon />}
              </a>
            </span>

            <ul>
              {Object.keys(MODULE_NAV_ITEMS).map((moduleCategory) => {
                const menuStateKey = camelCase(moduleCategory);
                const showCategory = some(
                  MODULE_NAV_ITEMS[moduleCategory],
                  showModuleNavItem.bind(this, user, modulesConfig),
                );

                if (!showCategory) {
                  return null;
                }

                return (
                  <NavItem
                    key={moduleCategory}
                    user={user}
                    isMenuOpen={menuState[menuStateKey]}
                    menuStateKey={menuStateKey}
                    moduleCategory={moduleCategory}
                    modulesConfig={modulesConfig}
                    currentQueryParams={router.query}
                    handleToggle={handleToggle}
                    activeClass={activeClass}
                  />
                );
              })}
            </ul>
          </li>

          {showModuleNavItem(
            user,
            modulesConfig,
            NAV_ITEMS.CUSTOMIZABLE_TABLE,
          ) && (
            <li
              key="CUSTOMIZABLE_TABLE"
              className={activeClass(NAV_ITEMS.CUSTOMIZABLE_TABLE)}
            >
              <Link href={NAV_ITEMS.CUSTOMIZABLE_TABLE}>
                <CustomizableTableIcon />
                Customizable Table
              </Link>
            </li>
          )}
          <li className={classNames(activeClass(NAV_ITEMS.REQUEST_A_REPORT))}>
            <Link href={NAV_ITEMS.REQUEST_A_REPORT}>
              <PersonIcon />
              Request a Report
            </Link>
          </li>
        </ul>
      </nav>
      <UserNav user={user} router={router} />
    </div>
  );
}

function UserNav({ user, router }) {
  const [open, setOpen] = useState(false);
  const initials = (user?.firstName || "")[0] + (user?.lastName || "")[0] || "";
  const canManageIntegrations = user?.csMetadata?.can_manage_integrations;
  const hasCrmIntegrated = user?.csMetadata?.has_crm_integrated;
  const fullName = user?.fullName
    ? trim(user.fullName)
    : [user?.given_name, user?.family_name].filter((x) => !!x).join(" ");
  const email = user?.email ? trim(user.email) : null;
  const username =
    fullName.length > 0 ? fullName : email?.length > 0 ? email : "User";

  const showSuperAdmin = user?.role === "super_admin" || user?.actsAs;

  const handleRestartOnboardingProgress = () =>
    router.push({
      pathname: "/team",
      query: { ...router.query, ...PENDO_GUIDE_PARAMS },
    });

  const handleClickUser = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <nav className={styles.userNav}>
      <ul>
        <li className={open ? styles.open : null}>
          <Popup
            closeOnInsideClick
            button={() => (
              <a className={styles.user} onClick={handleClickUser}>
                <span className={styles.profileImage}>{initials}</span>
                <span className={styles.userName}>{username}</span>
                <ChevronRight />
              </a>
            )}
            open={open}
            onClose={() => setOpen(false)}
            className={styles.popup}
          >
            <ul>
              {showSuperAdmin && (
                <li>
                  <Link href="/super-admin">
                    <Image
                      src="/assets/images/gear.svg"
                      width={24}
                      height={24}
                    />
                    Super Admin
                  </Link>
                </li>
              )}
              <li>
                <Link href="/settings">
                  <Image
                    src="/assets/images/gear.svg"
                    width="24px"
                    height="24px"
                  />
                  Settings
                </Link>
              </li>
              {canManageIntegrations === true && (
                <li>
                  <Link href="/integrations">
                    <Image
                      src="/assets/images/database-gear.svg"
                      width="24px"
                      height="24px"
                    />
                    Integrations
                  </Link>
                </li>
              )}
              {hasCrmIntegrated === true && (
                <li>
                  <Link href="/customer-accounts">
                    <ShareIcon />
                    CRM Accounts
                  </Link>
                </li>
              )}
              <li>
                <a onClick={handleRestartOnboardingProgress}>
                  <Image
                    src="/assets/images/repeat.svg"
                    width="24px"
                    height="24px"
                  />
                  Restart Onboarding
                </a>
              </li>
              <li>
                <a href="/api/auth/logout">
                  <Image
                    src="/assets/images/box-arrow-right.svg"
                    width="24px"
                    height="24px"
                  />
                  Sign out
                </a>
              </li>
            </ul>
          </Popup>
        </li>
      </ul>
    </nav>
  );
}

export default LeftPaneNav;
