import { atom } from "jotai";

export const ADMIN_VIEWER = "ADMIN";

export const adminAtom = atom({
  viewingDashboardAs: null,
  adminDashboardDropdownUsers: [],
  isDemoMode: false,
});

export const userMetadataAtom = atom({
  hasIntegration: false,
  canManageIntegrations: false,
});

export const dashboardSettingsAtom = atom({
  dateFilter: "",
  dateFilterInterval: "MONTHLY",
  searchTerm: "",
});

export const dashboardConfigAtom = atom({});
export const defaultDashboardConfigAtom = atom({});

export const scorecardDataAtom = atom(null);
export const remountDashboardAtom = atom(false);

export const headerBarAtom = atom({
  showDropdownMenu: false,
});
