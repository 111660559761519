import { useState, type MouseEventHandler } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import { trim } from "lodash";
import { useUser } from "@auth0/nextjs-auth0/client";
import { useRouter } from "next/router";

import Popup from "@/atoms/Popup";
import ChevronRight from "./chevron-right.svg";
import ShareIcon from "./share.svg";

import s from "./style.module.css";

const PENDO_GUIDE_PARAMS = {
  pendo: "ijc3ldsV-Aob7PzWFlKEUP8FAtg",
  step: "t5IJ7ydUV5vSMAQ1qtvX_APcqJ0",
};

function UserNav() {
  const router = useRouter();
  const { user } = useUser();

  const csMetadata: any = user?.csMetadata;

  const [open, setOpen] = useState(false);
  const initials = (user?.firstName || "")[0] + (user?.lastName || "")[0] || "";
  const canManageIntegrations = csMetadata?.can_manage_integrations;
  const hasCrmIntegrated = csMetadata?.has_crm_integrated;
  const fullName = user?.fullName
    ? trim(user.fullName as string)
    : [user?.given_name, user?.family_name].filter((x) => !!x).join(" ");
  const email = user?.email ? trim(user.email) : null;
  const username =
    fullName.length > 0 ? fullName : email?.length > 0 ? email : "User";

  const handleRestartOnboardingProgress = () =>
    router.push({
      pathname: "/team",
      query: { ...router.query, ...PENDO_GUIDE_PARAMS },
    });

  const handleClickUser: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const showSuperAdmin = user?.role === "super_admin" || user?.actsAs;

  return (
    <nav className={s.userNav}>
      <ul>
        <li className={open ? s.open : null}>
          <Popup
            closeOnInsideClick
            button={() => (
              <a onClick={handleClickUser}>
                <span className={s.profileImage}>{initials}</span>
                <span className={s.userName}>{username}</span>
                <ChevronRight />
              </a>
            )}
            open={open}
            onClose={() => setOpen(false)}
            className={s.popup}
          >
            <ul>
              {showSuperAdmin && (
                <li>
                  <Link href="/super-admin">
                    <Image
                      src="/assets/images/gear.svg"
                      width={24}
                      height={24}
                    />
                    Super Admin
                  </Link>
                </li>
              )}
              <li>
                <Link href="/settings">
                  <Image src="/assets/images/gear.svg" width={24} height={24} />
                  Settings
                </Link>
              </li>
              {canManageIntegrations === true && (
                <li>
                  <Link href="/integrations">
                    <Image
                      src="/assets/images/database-gear.svg"
                      width={24}
                      height={24}
                    />
                    Integrations
                  </Link>
                </li>
              )}
              {hasCrmIntegrated === true && (
                <li>
                  <Link href="/customer-accounts">
                    <ShareIcon />
                    CRM Accounts
                  </Link>
                </li>
              )}
              <li>
                <a onClick={handleRestartOnboardingProgress}>
                  <Image
                    src="/assets/images/repeat.svg"
                    width={24}
                    height={24}
                  />
                  Restart Onboarding
                </a>
              </li>
              <li>
                <a href="/api/auth/logout">
                  <Image
                    src="/assets/images/box-arrow-right.svg"
                    width={24}
                    height={24}
                  />
                  Sign out
                </a>
              </li>
            </ul>
          </Popup>
        </li>
      </ul>
    </nav>
  );
}

export default UserNav;
