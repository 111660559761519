import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import Image from "next/legacy/image";

import styles from "./style.module.css";
import Button from "@/atoms/Button";

function AccessPending() {
  useEffect(() => datadogRum.addError("AccessPending page shown"));
  return (
    <div className={styles.content}>
      <div className={styles.illustration}>
        <Image
          src="/assets/images/coming-soon-illustration.svg"
          width="280"
          height="256"
        />
      </div>

      <div className={styles.message}>
        <div className={styles.header}>Welcome!</div>
        <div className={styles.caption}>
          We will reach out soon to complete the signup.
        </div>
      </div>

      <Button
        href="mailto:support@lumopath.ai"
        target="_blank"
        rel="noreferrer"
      >
        Contact Support
      </Button>
    </div>
  );
}

export default AccessPending;
