import classnames from "classnames";

import Loading from "./loading.svg";

import styles from "./style.module.css";

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

export default function Loader({ width = 50, height = 50, className }: Props) {
  return (
    <Loading
      className={classnames(styles.root, className)}
      width={width}
      height={height}
    />
  );
}
