import { type InputHTMLAttributes, type ChangeEvent } from "react";
import styles from "./style.module.css";

function LegacyInput({
  placeholder = "",
  type,
  required,
  onChange,
  name,
  defaultValue,
  readOnly,
  ...otherProps
}: InputHTMLAttributes<HTMLInputElement>) {
  const conditionalProps = {
    onChange: (_e: ChangeEvent<HTMLInputElement>) => undefined,
    readOnly: undefined,
    disabled: undefined,
  };

  if (onChange) {
    conditionalProps.onChange = onChange;
  }

  if (readOnly) {
    conditionalProps.readOnly = readOnly;
    conditionalProps.disabled = true;
  }

  return (
    <input
      name={name}
      className={styles.input}
      placeholder={placeholder}
      required={required}
      type={type}
      defaultValue={defaultValue}
      autoComplete={type === "password" ? "current-password" : ""}
      {...conditionalProps}
      {...otherProps}
    />
  );
}

export default LegacyInput;
