import React, { type ErrorInfo } from "react";
import { ErrorBoundary as Boundary } from "react-error-boundary";
import Router from "next/router";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

import { env } from "@/lib/utils";
import NotFoundError from "@/molecules/NotFoundError";
import SystemError from "@/molecules/SystemError";
import AccessPending from "@/molecules/AccessPending";

enum ErrorType {
  NotFound,
  NoSession,
  Timeout,
  Unknown,
  AccessPending,
}

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function errorType(error: Error) {
  if (error?.message?.match(/not found/i)) {
    return ErrorType.NotFound;
  } else if (
    error?.message?.match(/The user does not have a valid session./i)
  ) {
    return ErrorType.NoSession;
  } else if (error?.message?.match(/timeout/i)) {
    // TODO: Heroku doesn't actually seem to return a specific error for timeouts :(.
    // We just get a generic 400. If we want to _really_ handle this case we need to
    // implement our own timeouts.
    return ErrorType.Timeout;
  } else if (error?.message?.match(/accesspending/i)) {
    return ErrorType.AccessPending;
  }
  return ErrorType.Unknown;
}

const handleError = (error: Error, info: ErrorInfo) => {
  switch (errorType(error)) {
    case ErrorType.NoSession:
      datadogLogs.logger.warn(
        "User did not have a valid session, logging out",
        info,
        error,
      );
      break;
    case ErrorType.NotFound:
      datadogLogs.logger.warn("Not found", info, error);
      break;
    case ErrorType.Timeout:
      datadogRum.addError(error, info);
      datadogLogs.logger.error("Error boundary reached: Timeout", info, error);
      break;
    case ErrorType.Unknown:
      datadogRum.addError(error, info);
      datadogLogs.logger.error("Error boundary reached: Unknown", info, error);
      break;
    default:
      datadogRum.addError(error, info);
      datadogLogs.logger.error("Error boundary reached: Unknown", info, error);
      break;
  }

  if (env === "development") {
    console.log(
      "🐕 This would have logged to datadog in staging and production 🐕",
      error,
      info,
    );
  }
};

const Fallback = (props: FallbackProps) => {
  const { error } = props;

  switch (errorType(error)) {
    case ErrorType.NoSession:
      // This will briefly flash but the user
      // should be immediately redirected in `errorHandler`
      // to the login page.
      return <></>;
    case ErrorType.NotFound:
      return <NotFoundError {...props} />;
    case ErrorType.Timeout:
      return <SystemError />;
    case ErrorType.Unknown:
      return <SystemError />;
    case ErrorType.AccessPending:
      return <AccessPending />;
    default:
      return <SystemError />;
  }
};

interface ErrorBoundaryProps {
  children: JSX.Element;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return (
    <Boundary FallbackComponent={Fallback} onError={handleError}>
      {children}
    </Boundary>
  );
};
