import { ReactElement } from "react";
import { isNil } from "lodash";
import classnames from "classnames";

import styles from "./style.module.css";

export type ToastType = "info" | "success" | "warning" | "error";

interface Props {
  toastType: ToastType;
  title: string;
  description: string | ReactElement;
}

export default function Toast({ toastType, title, description }: Props) {
  return (
    <div className={classnames(styles.container)}>
      <div
        className={classnames(styles.toastTypeIndicator, {
          [styles[toastType]]: true,
        })}
      />

      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <div
          className={classnames(styles.description, {
            [styles.hidden]: isNil(description),
          })}
        >
          {description}
        </div>
      </div>
    </div>
  );
}
